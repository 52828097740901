

.hid .k-grid-header {
    height: 0;
    border-bottom-width: 0;
    visibility: hidden;
    overflow: hidden;
    display: none;
}

product-name {
    font-style:italic;
    color:white;
    background-color:black;
}

