.App {
    text-align: center;
}

.app {
}


.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

my-app {
    padding: 0 !important;
}

.k-drawer-container {
    position: fixed;
    width: 100%;
    height: 100%;
}

.k-drawer-item {
    user-select: none;
}

.k-icon {
    font-size: 20px;
}

.custom-toolbar {
    width: 100%;
    background-color: #f6f6f6;
    line-height: 10px;
    border-bottom: inset;
    border-bottom-width: 1px;
    padding: 3px 8px;
    color: #656565;
}

.mail-box {
    margin-left: 20px;
    font-weight: bold;
    font-size: 17px;
}

/*.page ul {
    list-style: none;
    margin: 0;
    padding: 0;
}*/
/*
.page li {
    font-size: 1.2em;
    background: 0 0;
    border-radius: 0;
    border-width: 0 0 1px;
    border-color: rgba(33, 37, 41, 0.125);
    border-style: solid;
    line-height: 1.5em;
    padding: 1.09em .84em 1.23em .84em;
}*/

/* .page li:last-child {
        border: 0;
    }*/
my-app {
    display: block;
    width: 100%;
    height: 100%;
    overflow: auto;
    min-height: 80px;
    box-sizing: border-box;
    padding: 30px;
}



body {
    position: relative;
}

html,
body {
    height: 100%;
    width: 100%;
    font-family: Arial;
}

.k-overlay {
    background-color:lightgray!important;
}

.k-button k-button-md k-rounded-md k-button-solid k-button-solid-base {
    padding: 0;
}

.k-button {
    text-transform: none !important;
    font-weight: 500;
}

.k-badge-container {
    margin-right: 8px;
}

.title {
    font-size: 18px;
    margin: 0;
}

ul {
    font-size: 14px;
    list-style-type: none;
    padding: 0;
    margin: 10px 10px;
    /*display: flex;*/
}

li {
    margin: 0 10px;
}

    li:hover {
        cursor: pointer;
        color: #84cef1;
    }
/*
input {
    height: 25px;
  
}*/


.column {
    float: left !important;
    width: 50%;
    padding: 10px;
}

.tableCols {
    content: "";
    display: table !important;
    clear: both !important;
}

.loginField {
    height: 25px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 90%;
}

.loginButton {
    margin: 10px;
    width: 100%;
  
    padding: 10px 20px;
}


.button-container {
    display: flex;
    justify-content: center;
}

.login-form {
    background-color: white;
    padding: 2rem;
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    align-self: center;
    align-content: center;
    align-items: center;
    display: block;
    justify-content: center;
    justify-self: center;
    justify-content: center;
    width: 300px !important;
    /*margin-left: 30%;*/
}

.error {
    color: red;
    font-size: 12px;
}

.title {
    font-size: 25px;
    margin-bottom: 20px;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px;
}

.input-prefix {
    width: 100% !important;
    margin: 0px !important;
}


.k-grid-header {
    border-bottom-width: 1px;
    font-size: 14px;
    font-weight: bold;
}


.roundButton {
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 0px;
}

    .tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        color: black;
        font-size: 9pt;
        text-align: center;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
    }


        .tooltip .tooltiptext::after {
            content: "";
            position: absolute;
            top: -100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
        }

    .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }


.windowRight {
    z-index: 1000;
    top: 100px;
    display: block !important;
    position: absolute !important;
    right: 5px !important;
    margin-right: 5px !important;
    align-self: flex-end;
    border-width: 5px;
    background-color: white;
}

.popover {
    width: 250px !important;
    font-size: 8px !important;
}

.wrapper {
    display: inline-flex;
    flex-direction: column;
}

.k-drawer-item .k-icon {
    font-size: 16px !important;
    left: -10px !important;
    height: auto !important;
    width: auto !important;
    align-content: start !important;
}

.comboboxLarge {
    width: 300px;
    left: 10px;
    align-self: center;
    font-size: 20px !important;
    font-weight: bold;
    margin-bottom: 10px;
}


.k-animation-container {
    /*width: 100%;*/
    z-index: 10003 !important;
}


.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.photo {
    height: 100%;
    width: 100%;
    max-height:250px;
}


.selectedMenuItem {
    color: black !important;
    font-weight: bold !important;
    border-top-style: solid !important;
    border-left-style: solid !important;
    border-right-style: solid !important;
    border-color: darkgray !important;
    border-width: thin !important;
    /*background-color: aliceblue !important;*/
    width: 200px;
}
.menuItem {
    color: dodgerblue !important;
    border-bottom-style: solid !important;
    border-color: lightgray !important;
    border-width: thin !important;
    width: 200px;
}
.k-menu-link-text {
    color: #148E23
}
.k-icon {
    color: #148E23
}
.title {
    color: #148E23
}
.k-checkbox-label {
    vertical-align: top !important;
}

.buttonMandat {
    margin-left: 10px !important;
  
}
.buttonMandatNoPhoto {
    
}
.tableMandat {
    width: 500px;
    outline-width: 1px;
    outline-style: solid;
    outline-color:lightgray;
    height:100%;
}

.colDescription {
    visibility: visible;
    
}


@media (max-width: 800px) {
    .buttonMandat {
        transform: scale(1.5);
      /*  width: 60px !important;
        height: 60px !important;*/
        margin: 15px !important;
    }
    .buttonMandatNoPhoto {
     
       
    }

    .tableMandat {
        width: 100%;
       
    }
    .colDescription {
        visibility: hidden;
    }
/*    .k-grid td {
        visibility: hidden;
        width: 0px;
    }

    .k-column-title {
        visibility: hidden;
        width: 0px;
    }
    .k-table-th {
        visibility: hidden;
        width: 0px;
    }*/
}
.k-floating-label-container k-empty {
    visibility: hidden;
    width:0px;
}

.suffixLabel {
    position:relative;
    padding-top:5px;
    padding-left:10px;
    padding-right:180px;
}
.column-wrap {
    word-break: break-all
}
.k-table-td {
    word-break: break-all
}
.buttonPlus {
    border-radius: 50% !important;
    background-color: white !important;
    border-color: green !important;
    color: green!important;
    width:25px!important;
    height:25px!important;
}
